import { animate, style, transition, trigger } from '@angular/animations';
import { NgClass, NgComponentOutlet } from '@angular/common';
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, inject, OnInit } from '@angular/core';
import { SvgComponent } from 'icon';
import { Toast } from './toast.class';
import { ToastService } from './toast.service';

@Component({
  selector: 'lib-toasts',
  templateUrl: './toasts.component.html',
  styleUrls: ['./toasts.component.scss'],
  animations: [
    trigger('toast', [
      transition(':enter', [
        style({ opacity: 0, transform: 'translateY(-100%) scale(0.75)', position: 'relative' }),
        animate('250ms', style({ transform: 'translateY(0) scale(1)', opacity: 1 })),
      ]),
      transition(':leave', [
        style({ opacity: 1, transform: 'translateY(0) scale(1)', position: 'relative' }),
        animate('250ms', style({ transform: 'translateY(-100%) scale(0)', opacity: 0 })),
      ]),
    ]),
  ],
  imports: [NgClass, SvgComponent, NgComponentOutlet],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ToastsComponent implements OnInit {
  private toastsService = inject(ToastService);
  private cdr = inject(ChangeDetectorRef);
  toasts: Toast[] = [];

  ngOnInit() {
    this.toastsService.toasts.subscribe((resp: Toast) => {
      this.toasts.push(resp);
      this.cdr.markForCheck();
      setTimeout(() => {
        this.toasts.splice(this.toasts.indexOf(resp), 1);
        this.cdr.markForCheck();
      }, resp.duration);
    });
  }
}
